<template lang="pug">
.content
  section.container-fluid.p-t-20
    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(:to="{ name: 'Product Management', query: { paged } }") 产品管理
      a-breadcrumb-item {{ data.id ? '编辑产品' : '新增产品' }}

    .m-t-15.m-b-20
      h1.section-title.inline {{ data.id ? '编辑产品' : '新增产品' }}
      a-button.m-l-15(size="small" @click="() => { this.$router.push({ name: 'Product Management', query: { paged } }) }") 产品管理

  .container-fluid.clearfix(style="padding-right: 15px; margin-right: 335px;")
    .inner-content-right
      .editor-wrap
        a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
          a-input(size="large" v-model="data.title" placeholder="请输入品名")
          
        a-row(:gutter="[ 16, 16 ]")
          a-col(span="12")
            a-card.m-b-15(size="small" title="缩略图")
              small(slot="extra") {{ upload_options.thumbnail.tip }}
              vc-uploader(:data="data.thumbnail" :options="upload_options.thumbnail" @success="onSuccess")
          a-col(span="12")
            a-card.m-b-15(size="small" title="规格图")
              small(slot="extra") {{ upload_options.gallery.tip }}
              vc-uploader(:data="data.gallery" :options="upload_options.gallery" @success="onSuccess")
        
        a-row
          //- a-card.m-b-15(size="small" :bodyStyle="{ padding: 0 }")
            vc-tinymce(height="460" v-model="data.content" :height="120")
            
          a-card.m-b-15(size="small" title="温馨提示:")
            a-textarea(v-model="data.purchase_note" :auto-size="{ minRows: 5, maxRows: 7 }")

          a-card.m-b-15(size="small" title="AI 识图(用于识别瓶型，蓝色小点表示机器已学习)")
            small(slot="extra") {{ upload_options.aigallery.tip }}
            vc-uploader(:data="data.aigallery" :options="upload_options.aigallery" @success="onSuccess")

    .secondary-sidebar-right
      a-card.m-b-15(size="small" title="发布")
        a-form-item.m-b-10(label="状 态:" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }")
          a-select.full-width(v-model="data.status")
            //- a-select-option(value="pending") 待审核
            a-select-option(value="publish") 正常
            //- a-select-option(value="private") 私密
            a-select-option(value="draft") 草稿
            //- a-select-option(value="trash") 回收站
        
        a-form-item.m-b-10(label="排 序:" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }")
          a-input-number(v-model="data.menu_order" :min="0")

        //- a-form-model-item.m-b-0(label="是否上架")
          a-switch(v-model="data.onsale")

        a-button.submit(type="primary" block @click="submit") 提交
      
      a-card.m-b-15(size="small" title="分类")
        a-select.full-width(v-model="category_ids" placeholder="请选择分类" v-if="product_category.length")
          a-select-option(:value="item.id" v-for="(item, index) in product_category" :key="index") {{ item.name }}

      .cards-wrap
        .cards
          a-card.card.m-b-15(size="small" title="瓶型数据" v-for="(item, index) in data.cards" :key="index")
            a(slot="extra" @click="removeCard(index)" v-if="data.cards.length > 1") 删除
            a-form-model-item.m-b-10(label="货号:" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }")
              a-input(v-model="data.cards[index].item_no")
            a-form-model-item.m-b-10(label="尺寸:" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }")
              a-input(v-model="data.cards[index].size" placeholder="单位(mm)")
            a-form-model-item.m-b-10(label="口径:" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }")
              a-input-number(:min="0" v-model="data.cards[index].caliber" placeholder="单位(mm)")
            a-form-model-item.m-b-10(label="参考容量:" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }")
              a-input-number(:min="0" v-model="data.cards[index].capacity" placeholder="单位(ml)")
        .m-b-25
          a-button(@click="addCard") 增加瓶型数据
        

    //- section.container-fluid.p-t-20
      div(style="opacity:.35;width:280px;position:fixed;right:0;top:60px;border:solid 1px #ddd;background:#fff;padding:20px;z-index:1111;")
        pre(style="font-size:11px;") {{ data }}
      
    //- pre(style="font-size:10px;") {{ product_category }}
    //- pre(style="font-size:10px;") {{ product_tag }}
    //- pre(style="font-size:10px;") {{ tag_ids }}
    //- pre(style="font-size: 10px; clear: both;" v-if="user.display_name === 'vincent'") {{ data }}
    
</template>

<script>
import { mapState } from 'vuex'

import vcUploader from '@/components/vcUploader'
import vcTinyMCE from '@/components/vcTinyMCE'

export default {
  name: 'ProductForm',
  components: {
    'vc-uploader': vcUploader,
    'vc-tinymce': vcTinyMCE
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cards: [],
      product_category: [],
      product_tag: [],
      
      formItemLayout: {
        labelCol: {
          lg: { span: 2 },
          md: { span: 4 },
          sm: { span: 7 }
        },
        wrapperCol: {
          lg: { span: 14 },
          md: { span: 12 },
          sm: { span: 17 }
        },
      },
      
      upload_options: {
        thumbnail: {
          accept: ['.jpg', '.gif', '.png'],
          multiple: false,
          key: 'thumbnail',
          tip: '尺寸大小建议 360 × 360 像素',
          size: {
            width: 50,
            height: 20
          },
          extra_data: {
            app: 'admin',
            module: 'product',
          }
        },
        gallery: {
          accept: ['.jpg', '.gif', '.png'],
          multiple: false,
          key: 'gallery',
          tip: '尺寸大小建议 360 × 360 像素',
          size: {
            width: 50,
            height: 20
          },
          extra_data: {
            app: 'admin',
            module: 'product',
          }
        },
        aigallery: {
          preview: {
            size: [ 100, 100 ]
          },
          accept: ['.jpg', '.png'],
          multiple: true,
          maxfile: 10,
          key: 'aigallery',
          tip: '尺寸大小建议 680 × 680 像素',
          size: {
            width: 50,
            height: 20
          },
          extra_data: {
            app: 'admin',
            module: 'product',
          }
        }
      },
    }
  },
  computed: {
    ...mapState({
      ui: state => state.ui,
      user: state => state.account,
    }),
    category_ids: {
      get() {
        if (!this.data.categories) return [];
        return this.data.categories.map(item => item.id);
      },
      set(val) {
        const items = this.product_category.filter(item => val.includes(item.id));
        this.data.categories = items;
      }
    },
    paged() {
      return this.$route.query.paged
    }
  },
  watch: {
    //- "data": {
    //-   handler() {
    //-     console.log(this.data)
    //-     //- this.form.setFieldsValue({ 'title': 'AA' });
    //-     //- this.form.setFieldsValue({ 'content': 'BB' });
    //-   },
    //-   immediate: false,
    //- },
  },
  methods: {
    addCard() {
      this.data.cards.push({
        item_no: '',
        size: '',
        caliber: '',
        capacity: ''
      })
    },
    removeCard(i) {
      this.data.cards.splice(i, 1);
    },

    async submit() {
      // this.$refs.form.validate(async valid => {
      //   if (!valid) return this.$message.error('提交失败');
        try {
          const method = this.data.id ? 'put' : 'post';
          const url = this.data.id ? `product/${this.data.id}`: 'product';
          await this.$http[method](url, this.data);
          // this.$refs.form.resetFields();
          this.$message.success('提交成功');
        } catch(err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      // })
    },
    
    //- 获取分类标签
    async getTaxonomies(taxonomy) {
      this[taxonomy] = await this.$http.get(taxonomy, {
        params: {
          limit: -1
        }
      });
    },

    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      this.data[key] = res;
    }
  },
  created() {
    this.getTaxonomies('product_category');
  },
}
</script>


<style lang="less">
.secondary-sidebar-right {
  float: left;
  width: 310px;
  margin-right: -310px;
}
.inner-content-right {
  float: left;
  width: 100%;
  margin-right: 15px;
}

.product-form {
  border-top: solid 1px #ccc;
  padding: 10px 0;

  .ant-divider { margin: 16px 0;}
  .ant-form-item {
    margin: 12px 0;
  }
  .ant-card {
    .ant-form-item {
      margin: 6px 0;
      label { font-size: 12px;}
    }
    .ant-form-item-label { line-height: 35px;}
    .ant-form-item-control { line-height: 36px;}
    .ant-input,
    .ant-input-number {
      height: 30px;
    }
  }
  
  .cards-wrap { margin-left: 12.5%;}
  .cards {
    .card {
      width: 320px;
      float: left;
      margin: 0 15px 15px 0;
    }
    &::after {
      content: ''; display: block; clear: both;
    }
  }
}
</style>